import request from '~/utils/tc-request';

export const addMember = (data) => request.post('/service/member', data, { headers: { NoTraceKey: true } });
export const login = (data) => request.post('/service/auth/login', data);
export const logout = (data) => request.post('/service/auth/logout', data);
export const forgotPasswordWithBankAccount = (data) => request.post('/service/member/password/resetPwd', data);
export const changePassword = (data) => request.put('/service/member/password', data);
export const changePasswordWithoutOld = (data) => request.put('/service/member/updateMemberPassword', data);
export const getMemberData = () => request.post('/service/member/getOne');
export const getInviteLinks = () => request.post('/service/promotion/recommendShare/getOne');
export const getMemberBankList = () => request.post('/service/member/bank/getList');
export const updateMemberData = (data) => request.put('/service/member', data, { headers: { NoTraceKey: true } });
export const addMemberBank = (data) => request.post('/service/member/bank', data, { headers: { 'Content-Type': 'multipart/form-data' } });
export const addCryptoAccount = (data) => request.post('/service/member/bank/crypto', data, { headers: { 'Content-Type': 'multipart/form-data' } });
export const addEWalletAccount = (data) => request.post('/service/member/bank/eWallet', data, { headers: { 'Content-Type': 'multipart/form-data' } });
export const checkReferee = (data) => request.post('/service/member/checkReferee', data);
export const sendOTP = (data) => request.post('/service/mobile/check', data, { headers: { NoTraceKey: true } });
export const verifyOTP = (data) => request.post('/service/mobile/verify', data, { headers: { NoTraceKey: true } });
export const requestMobileVerify = (data) => request.post('/service/member/requestMobileVerify', data, { headers: { NoTraceKey: true } });
export const verifyMobile = (data) => request.post('/service/member/verifyMobile', data, { headers: { NoTraceKey: true } });
export const gcmsLogin = (data) => request.post('/service/auth/gcms/login', data);
export const subscribleNotify = (data) => request.post('/service/app/subscrible', data);
export const subscribeBeforeLogin = (data) => request.post('/service/app/subscribleBeforeLogin', data);
export const sendForgetPwdOTP = (data) => request.post('/service/member/password/forgetPwdOtp', data);
export const forgetPwd = (data) => request.post('/service/member/password/forgetPwd', data);
export const checkUsername = (data) => request.post('/service/member/checkUsername', data, { headers: { NoTraceKey: true } });
export const checkMemberMid = (data) => request.post('/service/member/mid', data);
export const checkPhoneNumber = (data) => request.post('/service/member/checkPhoneNumber', data, { headers: { NoTraceKey: true } });
export const getSNSUrl = (data) => request.post('/service/sns/oauth/getOauthUrl', data);
export const checkSNSAuthCode = (data) => request.post('/service/sns/oauth/checkAuthCode', data);
export const checkSNSAuthCodeWithToken = (data) => request.post('/service/sns/oauth/checkAuthCodeWithToken', data);
export const nativeFBLogin = (data) => request.post('/service/sns/native/facebookQuickLogin', data);
export const nativeFBBind = (data) => request.post('/service/sns/native/bindFacebook', data);
export const nativeFBForceBind = (data) => request.post('/service/sns/native/fbForceBind', data);
export const snsForceBind = (data) => request.post('/service/sns/oauth/forceBind', data);
export const getSNSBind = (data) => request.post('/service/sns/oauth/getBindingList', data);
export const getMemberBetLevel = (data) => request.post('/service/member/betLevel/getMemberBetLevelByMember', data);
export const getChatRoomList = (data) => request.post('/service/member/room/getList', data);
export const getOtherMemberInfo = (data) => request.post('/service/member/getOther', data);
export const joinChatRoom = (data) => request.post('/service/member/room/join', data);
export const guestLogin = (data) => request.post('/service/member/guestLogin', data);
export const getBonusMessages = (data) => request.post('/service/member/room/getBonus', data);
export const sendDm = (data) => request.post('/service/member/room/whisper/send', data, { headers: { NoTraceKey: true } });
export const getDmList = (data) => request.post('/service/member/room/whisper/getList', data);
export const getDmHistory = (data) => request.post('/service/member/room/whisper/getMsg', data);
export const deleteDm = (data) => request.post('/service/member/room/whisper/delete', data);
export const updateDmReadTime = (data) => request.post('/service/member/room/whisper/read', data);
export const getSuggestFriend = (data) => request.get('/service/member/room/getRecommendFriend', data);

export default {
  addMember,
  login,
  forgotPasswordWithBankAccount,
  changePassword,
  changePasswordWithoutOld,
  getMemberData,
  getInviteLinks,
  getMemberBankList,
  updateMemberData,
  addMemberBank,
  checkReferee,
  sendOTP,
  verifyOTP,
  addCryptoAccount,
  addEWalletAccount,
  requestMobileVerify,
  verifyMobile,
  gcmsLogin,
  subscribleNotify,
  subscribeBeforeLogin,
  sendForgetPwdOTP,
  forgetPwd,
  checkUsername,
  checkMemberMid,
  checkPhoneNumber,
  getSNSBind,
  getMemberBetLevel,
  getChatRoomList,
  getOtherMemberInfo,
  joinChatRoom,
  guestLogin,
  getBonusMessages,
  logout,
  sendDm,
  getDmList,
  getDmHistory,
  deleteDm,
  updateDmReadTime,
  getSuggestFriend,
};
