import request from '~/utils/tc-request';

export const getFinancialRecord = (data) => request.post('/service/money/record/getList', data);
export const getFinancialAllRecord = (data) => request.post('/service/financial/getRecord', data);
export const getOnlinePayment = (data) => request.post('/service/money/deposit/online', data);
export const googlePlayBilling = (data) => request.post('/service/money/deposit/online/googlePlayBilling', data);
export const applePayBilling = (data) => request.post('/service/money/deposit/online/applePayBilling', data);
export const onlinePaymentConfirm = (data) => request.post('/service/money/deposit/online/confirm', data);
export const getPaymentMethods = (data) => request.post('/service/money/getOneCategory', data, { headers: { AutoErrorPrint: false } });
export const getPaymentCategory = (data) => request.post('/service/money/getCategoryList', data, { headers: { AutoErrorPrint: false } });
export const getPaymentBankList = (data) => request.post('/service/member/bank/getPayBank', data, { headers: { AutoErrorPrint: false } });
export const addWithdrawOrder = (data) => request.post('/service/money/withdraw', data);
export const getAllPlatMoney = () => request.post('/service/money/ewallet/getList', null, { headers: { AutoLoading: false } });
export const transAllPlatformMoney = () => request.post('/service/money/ewallet/transferGameAll');
export const transPlatformMoney = (data) => request.post('/service/money/ewallet', data);
export const getPromotionWallet = (data) => request.post('/service/promotion/promotionWallet/getList', data, { headers: { AutoLoading: false } });
export const getManualDepositAccountList = () => request.post('/service/money/deposit/receiptAccount/getList');
export const getAutoCashInAccountList = () => request.post('/service/money/deposit/receiptAccount/getAutoCashInAccountList');
export const addManualDepositOrder = (data) => request.post('/service/money/deposit/manual', data, { headers: { 'Content-Type': 'multipart/form-data' } });
export const addRecoupmentOrder = (data) => request.post('/service/money/recoupment/', data, { headers: { 'Content-Type': 'multipart/form-data' } });
export const getRecoupmentRecord = (data) => request.post('/service/money/recoupment/getList', data);
export const getRewardList = (data) => request.post('/service/money/rewardPointRedeem/getRewardList', data, { headers: { AutoLoading: false } });
export const sendApplyRedeem = (data) => request.post('/service/money/rewardPointRedeem/apply', data);
export const getRedeemList = (data) => request.post('/service/money/rewardPointRedeem/getRedeemList', data);
export const getRedeemInfo = (data) => request.post('/service/money/rewardPointRedeem/getRedeemInfo', data);
export const getRewardImg = (data) => request.get(data);
export const getPointRedeemControl = () => request.post('/service/money/rewardPointRedeem/getPointRedeemControl');
export const checkWithdrawFee = (data) => request.post('/service/money/withdraw/fee/check', data);
export const getPaymentMerchantList = (data) => request.post('/service/money/deposit/online/getMerchantList', data);
export const getWithdrawSetting = () => request.post('/service/money/getWithdrawSettingInfo');
export const getWithdrawRemainingFlow = () => request.post('/service/money/withdraw/remainingFlow');

export default {
  getFinancialRecord,
  getFinancialAllRecord,
  getOnlinePayment,
  googlePlayBilling,
  applePayBilling,
  onlinePaymentConfirm,
  getPaymentMethods,
  getPaymentCategory,
  getPaymentBankList,
  addWithdrawOrder,
  getAllPlatMoney,
  transAllPlatformMoney,
  transPlatformMoney,
  getPromotionWallet,
  getManualDepositAccountList,
  getAutoCashInAccountList,
  addManualDepositOrder,
  addRecoupmentOrder,
  getRecoupmentRecord,
  getRewardList,
  sendApplyRedeem,
  getRedeemList,
  getRedeemInfo,
  getRewardImg,
  getPointRedeemControl,
  checkWithdrawFee,
  getPaymentMerchantList,
  getWithdrawSetting,
  getWithdrawRemainingFlow,
};
