import httpRequest from '../../utils/request';

const request = (...arg) => httpRequest.then((res) => res(...arg));

/**
 * 產品社群連結
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_CommunityLink(data) {
  return request({
    url: '/api/platform/product/community/list',
    method: 'post',
    data
  });
}

let service = {
  Get_CommunityLink
};

export default service;
