<template lang="pug">
.no-data
  img(src="@/assets/images/common/no-data.webp")
  p {{ $t('member.recommand.noData') }}
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins';
.no-data {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 10px;
  p {
    color: $text-main;
  }
}
</style>
