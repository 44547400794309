import request from '~/utils/tc-request';

export const getTournamentVisible = (data) => request.post('/service/promotion/tournament/visible', data);
export const getHistoryDetail = (data) => request.post('/service/promotion/tournament/getDetail', data);
export const getRankingByUser = (data) => request.post('/service/promotion/tournament/getRankingByUser', data);
export const getHistory = (data) => request.post('/service/promotion/tournament/getHistory', data);
export const getInProgressInfo = (data) => request.post('/service/promotion/tournament/getInfo', data);
export const getUnLoginInfo = (data) => request.post('/service/promotion/tournament/getUnLoginInfo', data);

export default {
  getTournamentVisible,
  getHistoryDetail,
  getRankingByUser,
  getHistory,
  getInProgressInfo,
  getUnLoginInfo,
};
