import base from './base';
import announcement from './announcement';
import member from './member';
import mail from './mail';
import financial from './financial';
import promotion from './promotion';
import game from './game';

export default {
  base,
  announcement,
  member,
  mail,
  financial,
  promotion,
  game,
};
