import httpRequest from '../../utils/request';

const request = (...arg) => httpRequest.then((res) => res(...arg));

/**
 * 金流頁面檢查
 * @param data
 * @returns {*}
 * @constructor
 */
function PaymentCheck(data) {
  return request({
    url: 'api/payment/check',
    method: 'post',
    data
  });
}

/**
 * Deposit
 * @param data
 * @returns {*}
 * @constructor
 */
function Deposit(data) {
  return request({
    url: '/api/payment/cashin',
    method: 'POST',
    data
  });
}

/**
 * Withdraw
 * @param data
 * @returns {*}
 * @constructor
 */
function Withdraw(data) {
  return request({
    url: '/api/payment/cashout',
    method: 'POST',
    data
  });
}

/**
 * TppPayment
 * @param data
 * @returns {*}
 * @constructor
 */
function TppPayment(data) {
  return request({
    url: '/api/payment/tpp/cashin',
    method: 'post',
    data
  });
}

/**
 * 取得存款贈群組
 * @param data
 * @returns {*}
 * @constructor
 */
function GetActivityDepositGroup(data) {
  return request({
    url: '/api/activity/deposit/group',
    method: 'post',
    data
  });
}

/**
 * 取得存款贈列表
 * @param data
 * @returns {*}
 * @constructor
 */
function GetActivityDeposit(data) {
  return request({
    url: '/api/activity/deposit/list',
    method: 'post',
    data
  });
}

/*
* 存款贈檢查
* */
function CheckActivityDeposit(data) {
  return request({
    url: '/api/activity/check',
    method: 'post',
    data
  });
}

/*
* 存款贈自動上分參加檢查
* */
function AutoDepositCheck(data) {
  return request({
    url: '/api/activity/deposit/autocheck',
    method: 'post',
    data
  });
}

/*
* 取得三方支付欄位
* */
function GetTppColumn(data) {
  return request({
    url: '/api/payment/tpp/columns',
    method: 'post',
    data
  });
}

/*
* 取得三方支付跳轉網址
* */
function GetTppURL(data) {
  return request({
    url: '/api/payment/tpp/submit',
    method: 'post',
    data
  });
}

let service = {
  Deposit,
  Withdraw,
  PaymentCheck,
  TppPayment,
  GetActivityDeposit,
  CheckActivityDeposit,
  GetActivityDepositGroup,
  AutoDepositCheck,
  GetTppColumn,
  GetTppURL
};

export default service;
