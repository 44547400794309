import httpRequest from '../../utils/request';

const request = (...arg) => httpRequest.then((res) => res(...arg));

/**
 * 取得遊戲列表
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_GameType(data) {
  return request({
    url: '/api/game/type',
    method: 'post',
    data
  });
}

/**
 * 取得遊戲列表
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_GameList(data) {
  return request({
    url: '/api/game/list',
    method: 'post',
    data
  });
}

/**
 * 取得遊戲記錄
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_GameBetRecord(data) {
  return request({
    url: '/api/record/game',
    method: 'post',
    data
  });
}

/**
 * 取得遊戲名稱列表
 * @param data
 * @returns {*}
 * @constructor
 */
// function Get_GameNameList(data) {
//   return request({
//     url: '/api/record/type/game',
//     method: 'post',
//     data
//   })
// }

/**
 * 取得遊戲類型列表
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_GameTypeList(data) {
  return request({
    url: '/api/record/type/gametype',
    method: 'post',
    data
  });
}

/**
 * 取得遊戲提供商列表
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_GameProviderList(data) {
  return request({
    url: '/api/game/platform/provider/list',
    method: 'post',
    data
  });
}

/**
 * 開啟遊戲
 * @param data
 * @returns {*}
 * @constructor
 */
function OpenGame(data) {
  return request({
    url: '/api/game/open',
    method: 'post',
    data
  });
}

/**
 * 取得top50遊戲
 * @param data
 * @returns {*}
 * @constructor
 */
function GetTop50Game(data) {
  return request({
    url: '/api/game/top50/list',
    method: 'post',
    data
  });
}

let service = {
  Get_GameList,
  Get_GameBetRecord,
  // Get_GameNameList: Get_GameNameList,
  Get_GameTypeList,
  Get_GameProviderList,
  OpenGame,
  Get_GameType,
  GetTop50Game
};

export default service;
