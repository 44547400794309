import Vue from 'vue';
import at from 'lodash/at';
import store from '@/store';
import axios from '~/utils/tc-request';

Vue.directive('imgBToA', {
  bind(el, binding, vnode) {
    const cmp = vnode.context;
    const parentCmp = Object.getPrototypeOf(cmp);
    const isFunctional = !Object.prototype.hasOwnProperty.call(cmp, '$options');
    const rootObject = isFunctional ? parentCmp : cmp;
    const url = binding.value;
    const expressionArr = binding.expression.split('.');
    const dataName = expressionArr[expressionArr.length - 1]; // fixed in functional
    const atObjectStr = isFunctional ? expressionArr.slice(1, expressionArr.length - 1).join('.') : expressionArr.slice(0, expressionArr.length - 1).join('.');
    const defineObject = (atObjectStr === '') ? rootObject : at(rootObject, atObjectStr)[0] || at(rootObject.$data, atObjectStr)[0] || at(rootObject.$props, atObjectStr)[0] || {};
    const dataDescriptor = Object.getOwnPropertyDescriptor(defineObject, dataName); // fixed in functional
    const dataSet = dataDescriptor ? dataDescriptor.set.bind(cmp) : window.noop;

    if (!isFunctional) {
      Object.defineProperty(defineObject, dataName, {
        set() {
          axios.post(url)
            .then(({ data: { captcha, captchaUid, remaining } }) => {
              let buf;
              try {
                buf = btoa(String.fromCharCode.apply(null, captcha.data));
                rootObject.captchaUid = captchaUid;
                rootObject.remaining = remaining;
                dataSet(`data:image/jpeg;base64,${buf}`);
                vnode.elm.src = `data:image/jpeg;base64,${buf}`;
              } catch (e) {
                console.error(new Error('Image set error!'));
                console.error(e);
              }
            });
        },
        get() {
          return rootObject;
        },
      });
    } else {
      console.error(new Error('imgBToA not support functional.'));
    }

    if (url) {
      // FIX ME
      // set(rootObject, binding.expression, url);
      rootObject[dataName] = url;
    }
  },
});
