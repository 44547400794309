import Vue from 'vue';
import imageCompression from 'browser-image-compression';

Vue.directive('fileRead', {
  bind(el, binding, vnode) {
    let cmp = vnode.context;
    let dataName = binding.expression;

    vnode.elm.onchange = (changeEvent) => {
      const isImage = vnode.elm.getAttribute('filetype') === 'image';
      let reader = new FileReader();
      reader.onload = async (loadEvent) => {
        let files = changeEvent.target.files || changeEvent.dataTransfer.files;
        const isLt1M = files[0].size / 1024 / 1024 < 1;

        if (!isImage) {
          cmp[dataName] = {
            file: changeEvent.target.files[0],
            fileRead: loadEvent.target.result,
          };
        }

        if (!isLt1M) {
          const options = {
            maxSizeMB: 0.8,
            useWebWorker: true,
          };

          try {
            cmp.$store.commit('app/SET_ISLOADING', true);
            const compressedFile = await imageCompression(files[0], options);
            cmp.$store.commit('app/SET_ISLOADING', false);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onloadend = function () {
              cmp[dataName] = {
                file: compressedFile, // api 吃 blob 格式
                fileRead: reader.result,
                fileToAPI: reader.result.split(',')[1],
              };
            };
          } catch (error) {
            console.log(error);
            cmp.$store.commit('app/SET_ISLOADING', false);
          }
        } else {
          cmp[dataName] = {
            file: changeEvent.target.files[0],
            fileRead: loadEvent.target.result,
            fileToAPI: loadEvent.target.result.split(',')[1],
          };
        }
      };
      reader.readAsDataURL(changeEvent.target.files[0]);
    };
  },
});
