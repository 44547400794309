import request from '~/utils/tc-request';

export const getPromotionInfoTagList = () => request.post('/service/promotion/getInfoTagList', {});
export const getPromotionInfo = (data) => request.post('/service/promotion/getList', data);
export const getPromotionInfoByUser = (data) => request.post('/service/promotion/getListByUser', data);
export const getVoucherCodePromotionInfo = (data) => request.post('/service/promotion/voucherCode/getPromotionInfo', data);
export const getPromotionInfoForCurrentMember = (data) => request.post('/service/promotion/voucher/getPromotionInfo', data);
export const joinPromotion = (data) => request.post('/service/promotion/preferApply/join', data);
export const joinVoucher = (data) => request.post('/service/promotion/voucher/join', data, { headers: { NoTraceKey: true } });
export const releasePromotion = (data) => request.post('/service/promotion/preferApply/release', data);

export const getPromotionRecommend = (data) => request.post('/service/promotion/recommendShare', data);
export const getPromotionRecommendMembers = (data) => request.post('/service/promotion/recommendShare/members', data);
export const getPromotionRecommendUrl = (data) => request.post('/service/promotion/recommendShare/getOne', data);
export const getPromotionHistory = (data) => request.post('/service/promotion/preferGift', data);
export const getLuckyDrawVisible = () => request.post('/service/promotion/lottery/visible');
export const getLuckyDrawInfo = (opts) => request.post('/service/promotion/lottery/getPromotionInfo', opts);
export const joinLuckyDrawPromotion = () => request.post('/service/promotion/lottery/joinLotteryPromotion');
export const getFlowInfo = () => request.post('/service/promotion/flow/getFlowInfo');
export const getLotteryTicket = () => request.post('service/promotion/lottery/settleAndDistribute');

export default {
  getPromotionInfoTagList,
  getPromotionInfo,
  getPromotionInfoByUser,
  getVoucherCodePromotionInfo,
  getPromotionInfoForCurrentMember,
  joinPromotion,
  releasePromotion,
  getPromotionRecommend,
  getPromotionRecommendMembers,
  getPromotionRecommendUrl,
  getPromotionHistory,
  getLuckyDrawVisible,
  getLuckyDrawInfo,
  joinLuckyDrawPromotion,
  getFlowInfo,
};
