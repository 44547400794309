import Big from 'big.js';
import { template } from 'lodash';
import moment from 'moment';
import { differenceInSeconds, secondsToHours, secondsToMinutes } from 'date-fns';
import store from '@/store';
import i18n from '@/language';
import rewardPointRedeem from '~/utils/constant/rewardPointRedeem';
import constants from '~/utils/constant';
import Precision from '../utils/Decimal';

export { parseTime, formatTime } from '../utils';

function toThousandMark(num) {
  return num
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

function financial(x, point = 2) {
  if (point === 0) return x;
  const rex = /([0-9]+\.[0-9]{2})[0-9]*/;
  let s = String(x).replace(rex, '$1');
  let rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + point) {
    s += '0';
  }
  return s;
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return `${time + label}s`;
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  }
  if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  }
  return pluralize(~~(between / 86400), ' day');
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      );
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000" or 1000000 => 1000K
 * @param {number} num
 */
export function toThousandFilter(num, point = 2) {
  if ((!num && num !== 0) || num.toString() === 'NaN') {
    return '-';
  }
  // num = Math.abs(num)
  let changeNum = 0;
  if (store.getters.CurrencyID === 5) {
    if (num === 0) {
      changeNum = financial(0);
    } else if (Math.abs(num) >= 1000) {
      const num_divide = Precision.divide(num, 1000);
      changeNum = `${toThousandMark(financial(num_divide, point))}K`;
    } else {
      changeNum = toThousandMark(financial(num, point));
    }
  } else {
    changeNum = toThousandMark(financial(num, point));
  }

  return changeNum;
}

/**
 * 超過1000顯示1K, 最多小數點後兩位
 * @param {number} num
 */
export function diamondFilter(num) {
  let changeNum;
  if (num === 0) {
    return 0;
  }
  Big.RM = 0; // round down mode;
  const roundNum = new Big(num).toFixed(0);
  const isGreaterThan1000 = Math.abs(roundNum) >= 1000;
  const num_divide = isGreaterThan1000 ? Precision.divide(roundNum, 1000) : roundNum;
  const rex = /([0-9]+\.[0-9]{2})[0-9]*/;
  const s = String(num_divide).replace(rex, '$1');
  const point = s.split('.')[1] ? s.split('.')[1].length : 0;
  const res = toThousandMark(financial(num_divide, point));
  changeNum = isGreaterThan1000 ? `${res}K` : res;

  return changeNum;
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatBirthday(value) {
  if (value && value !== '-') {
    return moment(value).format('YYYY-MM-DD');
  }
  return value;
}

// 轉為千分號
export function currency(price) {
  // return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  // price = Math.abs(price)
  if (price && /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,20})?$/g.test(price)) {
    if (store.getters.CurrencyID === 5) {
      if (price === 0) {
        return financial(0);
      }
      if (Math.abs(price) >= 1000) {
        const price_divide = Precision.divide(price, 1000);
        return `${toThousandMark(financial(price_divide))}K`;
      }
      return toThousandMark(financial(price));
    }
    return toThousandMark(financial(price));
    // return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }
  return financial(0);

  /* return price.toLocaleString('en-US');  寫法二：轉為千分號 */
}

// 添加$字號
export function filterDollarSign(price) {
  return `$ ${price}`;
}

export function time(val, format = 'YYYY/MM/DD') {
  return moment(val).format(format);
}

export function toFixedMoney(value, options = { fixedNum: undefined }) {
  if (value === null) return;
  let newValue = Number(value);
  let parseValue;
  let fixedNum;

  if (Number.isNaN(newValue)) {
    newValue = value;
  } else {
    Big.RM = 0; // round down mode;
    parseValue = new Big(value);
    fixedNum = options.fixedNum !== undefined ? options.fixedNum : store.getters['initData/getInitDataByPath'](
      'systemConfig.system.config.currency.clientDecimalPlaces',
    );
    newValue = toThousandMark(parseValue.toFixed(fixedNum));
  }

  return newValue;
}

export function referralStatusCode(value) {
  switch (Number(value)) {
    case -1:
      return i18n.t('common.status.unsettled');
    case 0:
      return i18n.t('common.auditing');
    case 1:
      return i18n.t('common.status.allowPayment');
    case 2:
      return i18n.t('common.status.denyPayment');
    case 4:
      return i18n.t('common.status.manual');
    default:
      return i18n.t('common.status.unpaid');
  }
}

export function percent(value = 0, fixedNum = 0) {
  Big.RM = 0; // round down mode;
  const num = value < 1 ? Big(value).times(100) : Big(value);
  return `${Number(num.toFixed(fixedNum).toString())}%`;
}

export function withdrawStatusCode(value) {
  const {
    withdraw: { status },
  } = constants.financial;
  switch (Number(value)) {
    case status.SUCCESS:
      return i18n.t('common.success');
    case status.REJECT:
    case status.EXCEPTION:
      return i18n.t('common.system.buttom.reject');
    default:
      return i18n.t('common.auditing');
  }
}

export function depositStatusCode(value) {
  const {
    deposit: { status },
  } = constants.financial;
  switch (Number(value)) {
    case status.SUCCESS:
    case status.FORCE_SUCCESS:
      return i18n.t('common.success');
    case status.REJECT:
    case status.FORCE_REJECT:
    case status.EXCEPTION:
      return i18n.t('common.system.buttom.reject');
    default:
      return i18n.t('common.auditing');
  }
}

export function currencySymbol(value) {
  let newString = value;
  let symbolKeyName = store.getters['initData/getSystemConfig'](
    'system.config.currency.symbol',
  );
  if (value !== undefined && symbolKeyName !== 'COIN') {
    newString = `${i18n.t(`common.currencySymbol.${symbolKeyName}`)} ${value}`;
  }
  return newString;
}

export function reward(value) {
  const { type } = rewardPointRedeem;
  switch (value) {
    case type.PRODUCT:
      return i18n.t('Product');
    case type.CREDIT:
      return i18n.t('Credit');
    default:
      return '';
  }
}

export function promotionGameRandomAmount({ money, isBonusGame }) {
  if (money === undefined || money === null) return '---';
  if (new Big(money).eq(0) && isBonusGame) {
    return i18n.t('member.promotion.randomAmount');
  }
  if (value !== undefined && symbolKeyName !== 'COIN') {
    let symbolKeyName = store.getters['initData/getSystemConfig'](
      'system.config.currency.symbol',
    );
    let fixedNum = store.getters['initData/getSystemConfig'](
      'system.config.currency.clientDecimalPlaces',
    );
    return `${i18n.t(`common.currencySymbol.${symbolKeyName}`)} ${new Big(money)
      .toFixed(fixedNum)
      .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))}`;
  }
  return money;
}

const secondsToDays = (seconds) => Math.trunc(secondsToHours(seconds) / 24);
export function lastLoginTime(val) {
  const seconds = differenceInSeconds(Date.now(), new Date(val));
  if (seconds < 120) return template(i18n.t('vue.common.minutesAgo'))({ value: 1 });
  if (seconds < 3600) return template(i18n.t('vue.common.minutesAgo'))({ value: secondsToMinutes(seconds) });
  if (seconds < 86400) return template(i18n.t('vue.common.hoursAgo'))({ value: secondsToHours(seconds) });
  if (seconds < 2592000) return template(i18n.t('vue.common.daysAgo'))({ value: secondsToDays(seconds) });
  return i18n.t('vue.common.moreThanOneMonthAgo');
}

