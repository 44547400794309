import request from '~/utils/tc-request';

export const getInitData = (data) => request.post('/service/initData', data);

/**
 * 取得語系列表
 * @param {} data
 * @returns {Promise}
 */
export const getLanguageList = (data) => request.post('/service/info/language/getList', data);

/**
 * 取得語言包
 * @param {{ lang: string }} data
 * @returns {Promise}
 */
const getLanguageData = (data) => request.post('/service/i18n/getOne', data);

/**
 * 取得維護資訊
 * @returns {Promise}
 */
export const getMaintainInfo = () => request.get('/service/info/system/config/getMaintainSetting');

export default {
  getInitData,
  getLanguageData,
  getLanguageList,
  getMaintainInfo,
};
