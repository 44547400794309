import axios from 'axios';
import request from '~/utils/tc-request';

export const CancelToken = axios.CancelToken;
let cancel;

const queryGameReq = (data, url) => {
  if (cancel) cancel();
  return request.post(`/service/game/${url}`, data, {
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    headers: { AutoLoading: false },
  });
};
export const queryGame = (data) => queryGameReq(data, 'getList');
export const queryGameByUser = (data) => queryGameReq(data, 'getListByUser');
export const queryGameWithoutCancel = (data) => request.post('/service/game/getList', data, { headers: { AutoLoading: false } });
export const queryGameByUserWithoutCancel = (data) => request.post('/service/game/getListByUser', data, { headers: { AutoLoading: false } });
export const queryGameTag = (data) => request.post('/service/game/tag', data);
export const queryGameTagByGameClass = (data) => request.post('/service/game/tag/getList', data);
export const launchUrl = (data) => request.post('/service/game/launchUrl', data);
export const launchDemoUrl = (data) => request.post('/service/game/launchUrl/demo', data);
export const addFavorite = (data) => request.post('/service/game/favoriteGame', data, { headers: { AutoLoading: false } });
export const removeFavorite = (data) => request.delete('/service/game/favoriteGame', { headers: { AutoLoading: false }, data });
export const queryBetRecordGameKindLists = () => request.post('/service/game/betLog/getGameKindList');
export const queryBetRecords = (data) => request.post('/service/game/betLog/dailySummary', data);
export const queryGameInfo = (data) => request.post('/service/game/getOne', data);

export default {
  queryGame,
  queryGameByUser,
  queryGameWithoutCancel,
  queryGameByUserWithoutCancel,
  queryGameTag,
  queryGameTagByGameClass,
  launchUrl,
  launchDemoUrl,
  addFavorite,
  removeFavorite,
  queryBetRecordGameKindLists,
  queryBetRecords,
  queryGameInfo,
};
