import Vue from 'vue';
import { isAndroid } from '~/utils';

const ROOT_EL_CLASS = 'focus-to-input';
const tagWhiteList = new Set(['input', 'textarea']);
const typeBlackList = new Set([
  'button',
  'checkbox',
  'date',
  'file',
  'image',
  'radio',
  'time',
]);
const invalidClass = new RegExp(/^el-input\w*$/);
const isInvalidTarget = ({ classList, tagName, type }) => {
  if (
    Array.from(classList.values()).some((className) => invalidClass.test(className))
  ) {
    return true;
  }
  if (!tagWhiteList.has(tagName.toLowerCase())) return true;
  if (typeBlackList.has(type.toLowerCase())) return true;
  return false;
};
let isFocus = false;

Vue.directive('focus-to-input', {
  bind(el, binding, vnode) {
    if (!isAndroid()) return;

    el.classList.add(ROOT_EL_CLASS);
    if (!el.classList.contains(ROOT_EL_CLASS)) return;

    const child = document.createElement('div');
    child.classList.add('extra-height');
    el.appendChild(child);
    el.classList.add('overflow-hidden');
    el.addEventListener('scrollend', () => {
      el.classList.remove('overflow-auto');
      el.classList.add('overflow-hidden');
    });

    el.addEventListener(
      'focus',
      (event) => {
        if (isInvalidTarget(event.target)) return;
        child.classList.remove('hidden');

        const clientHeight = document.body.clientHeight;
        const scrollTop = el.scrollTop;
        const top = event.target.getBoundingClientRect().top;

        isFocus = true;
        el.classList.add('overflow-auto');
        el.scrollTo({
          behavior: 'smooth',
          top: scrollTop + top - (binding.value ?? 0) - clientHeight * 0.05,
          left: 0,
        });
      },
      true,
    );
    el.addEventListener(
      'blur',
      (event) => {
        if (isInvalidTarget(event.target)) return;
        child.classList.add('hidden');
        isFocus = false;

        setTimeout(() => {
          if (isFocus) return;
          el.scrollTo({
            behavior: 'smooth',
            top: 0,
            left: 0,
          });
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
          });
        }, 0);
      },
      true,
    );
  },
});
