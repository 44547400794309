<template lang="pug">
div(class="table-wrapper" ref="table")
  div(class="table-header")
    div.table-cell.header-cell(v-for="(item, index) in headers" :style="{ flex: item.flex || 1}")
        | {{ $t(item.locale) }}
  template(v-if="source?.length > 0")
    .table-row(v-for="(item, index) in source" :key="index")
      .table-cell(v-for="(column, columnIndex) in columns" :style="{ flex: headers[columnIndex]?.flex || 1}")
        slot(v-if="column.customField" :name="column.name" :data="item")
        span(v-else) {{ formatter(source[index], column, item[column], index) }}
  NoData(v-else)
</template>
<script>
import NoData from '~/components/noData';

export default {
  name: 'TableList',
  components: {
    NoData,
  },
  inject: ['isRotate'],
  props: {
    source: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Function,
      default: () => {},
    },
    customClass: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    source: {
      deep: true,
      handler(v) {
        this.$nextTick(() => {
          if (v.length > 0) {
            setTimeout(() => {
              this.$refs.table?.scrollTo({
                top: 0,
              });
            });
          }
        });
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/components/TableList/index.scss';
.table-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  font-size: torem(12);
  color: #fff;
  font-weight: 700;
  background: #35026E99;
  height: 100%;
  overflow: auto;
  @include custom-scrollbar
}

.table-header {
  display: flex;
  background: #36026F;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 2px 5px #333;
}

.table-row {
  display: flex;
  align-items: center;
  min-height: 70px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 10px);
    height: calc(100% - 5px);
    background: url('~@/assets/images/common/tr-bg.webp') left center / 60% auto repeat-y, linear-gradient(180deg, #5929D1, #5B25C7 25%, #5E22BD 50%, #601FB4 75%, #601CAD);
    border-radius: 15px;
    box-shadow: 0px 2px 2px 0px #F5F5F580 inset, 0px 4px 4px 0px #00000040;
    z-index: 0;
  }
}

.table-cell {
  padding: 10px;
  text-align: center;
  overflow: hidden;
  word-break: break-word;
  word-wrap: pre-wrap;
  z-index: 1;
}

.header-cell {
  font-weight: bold;
  text-align: center;
}
</style>
