import Vue from 'vue';

Vue.directive('uppercase', {
  update(el, binding) {
    if (binding.value === false) return;

    const sourceValue = el.value;
    const newValue = binding.value === 'lower'
      ? sourceValue.toLowerCase()
      : sourceValue.toUpperCase();

    if (sourceValue !== newValue) {
      const len = newValue.length;
      let index = 0;
      for (let i = 0; i < len; ++i) {
        if (newValue[i] !== sourceValue[i]) {
          index = i + 1;
        }
      }
      index = index || len;

      el.value = newValue;
      el.dispatchEvent(new Event('input', { bubbles: true }));

      // 讓指標指到修改的地方
      el.selectionStart = index;
      el.selectionEnd = index;
    }
  },
});
