<template lang="pug">
.root(v-if="totalCount && totalCount > 0")
  .total-count(v-if="showTotalCount")
    span {{ $t('el.pagination.total', { total: totalCount }) }}
  .pagelist
    .page-item(data-at-element="pagination-prev-btn" @click="prevPage(currentPage)" :disabled="isPrevDisabled")
      div
        i.el-icon-caret-left
    .page-item-center
      div {{ currentPage }}
      div /
      div {{ totalPage }}
    .page-item(data-at-element="pagination-next-btn" @click.prevent="nextPage(currentPage)" :disabled="isNextDisabled")
      div
        i.el-icon-caret-right
</template>

<script>
import paginationMixins from '~/mixins/pagination';

export default {
  mixins: [paginationMixins],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pagination';
</style>
