import request from '~/utils/tc-request';

export const getDailyReport = ({ searchDate, actionType }) => (
  request.post(`/service/promotion/${actionType}/getDailyReport`, { searchDate }, { headers: { AutoLoading: false } })
);

export const getMonthWinnerVideo = ({ actionType }) => (
  request.post(`/service/promotion/${actionType}/getMonthWinnerVideo`)
);

export const getTotalList = ({ actionType }) => (
  request.post(`/service/promotion/${actionType}/getTotalList`, {}, { headers: { AutoLoading: false } })
);

export const getFrontEndReport = ({ actionType, startIndex, endIndex }) => (
  request.post(`/service/promotion/${actionType}/getFrontEndReport`, {
    startIndex,
    endIndex,
  }, { headers: { AutoLoading: false } })
);
