import httpRequest from '../../utils/request';

const request = (...arg) => httpRequest.then((res) => res(...arg));

function GetList(data) {
  return request({
    url: '/api/platform/language/list',
    method: 'post',
    data
  });
}

let service = {
  GetList
};

export default service;
