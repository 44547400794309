import request from '~/utils/tc-request';

export const searchMembers = (data) => request.post('/service/member/room/searchMembers', data, { headers: { NoTraceKey: true } });
export const getFriendList = (data) => request.post('/service/member/room/getFriendList', data);
export const applyFriend = (data) => request.post('/service/member/friendShip/applyFriend', data, { headers: { NoTraceKey: true } });
export const getFriendRequestList = (data) => request.post('/service/member/room/getFriendRequestList', data);
export const acceptFriendRequest = (data) => request.post('/service/member/friendShip/acceptedRequest', data, { headers: { NoTraceKey: true } });
export const rejectFriendRequest = (data) => request.post('/service/member/friendShip/rejectedRequest', data);
export const removeFriend = (data) => request.post('/service/member/friendShip/removeRequest', data);
export const getFriendInfo = (data) => request.post('/service/member/room/getOther', data);

export default {
  searchMembers,
  getFriendList,
  applyFriend,
  getFriendRequestList,
  acceptFriendRequest,
  rejectFriendRequest,
  removeFriend,
  getFriendInfo,
};

