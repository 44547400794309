import httpRequest from '../../utils/request';

const request = (...arg) => httpRequest.then((res) => res(...arg));

/**
 * 每日任務看板
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_DailyMissionList(data) {
  return request({
    url: '/api/activity/daily/list',
    method: 'post',
    data
  });
}

/**
 * 每日任務長週期-取得週期資訊
 * @param {{} | undefined} data
 * @returns {Promise}
 */
function Get_LongtermMission(data) {
  return request({
    url: '/api/activity/daily/longperiod/list',
    method: 'post',
    data
  });
}

/**
 * 每日任務獎勵領取
 * @param data
 * @returns {*}
 * @constructor
 */
function TakeReward(data) {
  return request({
    url: '/api/activity/daily/receive',
    method: 'post',
    data
  });
}

/**
 * 取得大獎
 * @param data
 * @returns {*}
 * @constructor
 */
function TakeBigReward(data) {
  return request({
    url: '/api/activity/daily/bonus',
    method: 'post',
    data
  });
}

/**
 * 取得長週期獎勵
 * @param {{ SettingDetailID: number }} data
 * @returns {Promise}
 */
function TakeLongtermReward(data) {
  return request({
    url: '/api/activity/daily/longperiod/receive',
    method: 'post',
    data
  });
}

/**
 * 活動看板
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_PromotionList(data) {
  return request({
    url: '/api/activity/list',
    method: 'post',
    data
  });
}

/**
 * 參加活動
 * @param data
 * @returns {*}
 * @constructor
 */
function Join_Promotion(data) {
  return request({
    url: '/api/activity/join',
    method: 'post',
    data
  });
}

/**
 * 完成活動
 * @param data
 * @returns {*}
 * @constructor
 */
function Finish_Promotion(data) {
  return request({
    url: '/api/activity/finish',
    method: 'post',
    data
  });
}

/**
 * 完成活動
 * @param data
 * @returns {*}
 * @constructor
 */
function GetUserLevelIntro(data) {
  return request({
    url: 'api/user/level/introduction',
    method: 'post',
    data
  });
}

/**
 * 取得是否有每日獎勵未領
 * @param data
 * @returns {*}
 * @constructor
 */
function GetHasDailyReward(data) {
  return request({
    url: 'api/activity/daily/check',
    method: 'post',
    data
  });
}

/**
 * 取得是否有抽獎活動
 * @param data
 * @returns {*}
 * @constructor
 */
function GetIsLotteryOpen(data) {
  return request({
    url: '/api/activity/lottery/check',
    method: 'post',
    data
  });
}

function GetHasPromotionCanFinish(data) {
  return request({
    url: 'api/user/check/activity/promotion',
    method: 'post',
    data
  });
}

/**
 * 取得是否是存款贈活動可參加
 * @param data
 * @returns {*}
 * @constructor
 */

function GetHasSpecialOffer(data) {
  return request({
    url: 'api/user/check/activity/deposit',
    method: 'post',
    data
  });
}

/**
 * 取得抽獎活動資訊
 * @param data
 * @returns {*}
 * @constructor
 */
function GetLotteryInfo(data) {
  return request({
    url: '/api/activity/lottery/info',
    method: 'post',
    data
  });
}

/**
 * 觸發抽獎
 * @param data
 * @returns {*}
 * @constructor
 */
function LotteryDraw(data) {
  return request({
    url: '/api/activity/lottery/draw',
    method: 'post',
    data
  });
}

/**
 * 取得鑽石商城資訊
 * @param data
 * @returns {*}
 * @constructor
 */
function GetDiamondShopInfo(data) {
  return request({
    url: '/api/diamondmall/info',
    method: 'post',
    data
  });
}

/**
 * 取得鑽石商城是否開啟
 * @param data
 * @returns {*}
 * @constructor
 */
function GetIsDiamondShopOpen(data) {
  return request({
    url: '/api/diamondmall/check',
    method: 'post',
    data
  });
}

/**
 * 鑽石商城購買
 * @param data
 * @returns {*}
 * @constructor
 */
function DiamondShopBuy(data) {
  return request({
    url: '/api/diamondmall/exchange',
    method: 'post',
    data
  });
}

/**
 * 取得是否有活動可解除
 * @param data
 * @returns {*}
 * @constructor
 */
function checkCanFinish(data) {
  return request({
    url: '/api/user/check/activity/promotion',
    method: 'post',
    data
  });
}

/**
 * 檢查優惠碼
 * @param data
 * @returns {*}
 * @constructor
 */
function checkPromoCode(data) {
  return request({
    url: '/api/activity/promocode',
    method: 'post',
    data
  });
}

/**
 * 取得活動群組
 * @returns {Promise}
 */
function GetDepositGroup() {
  return request({
    url: '/api/activity/deposit/group',
    method: 'post'
  })
}

/**
 * 取得參加中的存款贈活動
 * @returns {Promise}
 */
function GetJoinedAutoDepositPromotion() {
  return request({
    url: '/api/activity/deposit/processing',
    method: 'get'
  })
}

/**
 * 取得參加中的存款贈活動
 * @returns {Promise}
 */
function CancelJoinedAutoDepositPromotion(data) {
  return request({
    url: '/api/activity/deposit/cancel',
    method: 'post',
    data
  })
}

var service = {
  Get_DailyMissionList: Get_DailyMissionList,
  Get_LongtermMission,
  Get_PromotionList,
  TakeReward,
  TakeBigReward,
  TakeLongtermReward,
  Join_Promotion,
  Finish_Promotion,
  GetUserLevelIntro,
  GetHasDailyReward,
  GetLotteryInfo,
  GetIsLotteryOpen,
  LotteryDraw,
  GetHasPromotionCanFinish,
  GetHasSpecialOffer,
  GetDiamondShopInfo,
  GetIsDiamondShopOpen,
  DiamondShopBuy,
  checkCanFinish,
  checkPromoCode,
  GetDepositGroup,
  GetJoinedAutoDepositPromotion,
  CancelJoinedAutoDepositPromotion
}

export default service;
