import Vue from 'vue';
import Clipboard from 'clipboard';

Vue.directive('clipboard', {
  bind(el, binding, vnode) {
    let clipboard = new Clipboard(vnode.elm);
    let cmp = vnode.context;

    clipboard.on('success', (e) => {
      cmp.$notify.success({
        title: cmp.$t('common.title'),
        // FIX ME
        message: cmp.$t('member.recommand.copySuccess'),
      });
    });
    clipboard.on('error', (e) => {
      cmp.$notify.success({
        title: cmp.$t('common.title'),
        // FIX ME
        message: cmp.$t('member.recommand.copyFail'),
      });
    });

    cmp.clipboard = clipboard;
  },
});
