import request from '~/utils/tc-request';

export const getActivityData = () => request.post('/service/missionCenter/dailyMission/activity');
export const getMissionList = () => request.post('/service/missionCenter/dailyMission/getList');
export const getActivityReward = (data) => request.post('/service/missionCenter/dailyMission/activityBox', data);
export const getMissionReward = (data) => request.post('/service/missionCenter/dailyMission/taskBox', data);
export const getAllMissionReward = (data) => request.post('/service/missionCenter/dailyMission/allTaskBoxes', data);
export const getTaskHistory = (data) => request.post('/service/missionCenter/dailyMission/history', data);

export default {
  getActivityData,
  getMissionList,
  getActivityReward,
  getMissionReward,
  getAllMissionReward,
  getTaskHistory,
};
