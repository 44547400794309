import request from '~/utils/tc-request';

export const getMemberRankList = () => request.post('/service/member/rank/getList');
export const getMemberAnalysis = () => request.post('/service/member/analysis/getOne');
export const upgradeMemberRank = () => request.put('/service/member/rank/checkRank');
export const getMemberRankAccumulationDay = () => request.get('/service/member/rank/getAccumulationDay');

export default {
  getMemberRankList,
  getMemberAnalysis,
  upgradeMemberRank,
  getMemberRankAccumulationDay,
};
