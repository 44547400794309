import request from '~/utils/tc-request';

export const getMailList = (opts) => request.post('/service/mail/getList', opts, { headers: { AutoLoading: false } });
export const updateMail = (opts) => request.put('/service/mail', opts, { headers: { AutoLoading: false } });
export const getBonusList = (opts) => request.post('/service/mail/getBonusList', opts);
export const drawBonus = (opts) => request.put('/service/mail/bonus', opts);
export const getNews = (opts) => request.post('/service/info/announcement/getList', opts);
export const setAllMailStatus = (opts) => request.put('/service/mail/setAllMailStatus', opts);

export default {
  getMailList,
  updateMail,
  getBonusList,
  drawBonus,
  setAllMailStatus,
};
